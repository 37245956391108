<template>
  <div>
    <CCard accentColor="primary">
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid" />
          {{ title }}
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow v-show="!formVisibility">
          <CCol md="12">
            <div role="group" class="form-group">
              <label class>Nama OPD/SKPD</label>
              <v-select
                v-model="form.id_unit_kerja"
                :options="optionsOPD"
                label="nama_unit_kerja"
                :reduce="(opd) => opd.id_unit_kerja"
                :filterable="true"
                @search="onSearchOPD"
              ></v-select>
            </div>
          </CCol>
        </CRow>
        <CButtonGroup size="sm">
          <CButton color="info" v-show="!formVisibility" @click="loadData()">
            <CIcon name="cil-search" />Cari
          </CButton>
          <CButton color="warning" v-show="!formVisibility" @click="reset()">
            <CIcon name="cil-loop-circular" />Reset
          </CButton>
          <CButton
            color="success"
            v-show="!formVisibility"
            @click="exportExcel()"
          >
            <CSpinner v-show="loading_export" size="sm" class="mr-2" />
            <CIcon
              name="cil-file"
              v-show="!loading_export"
              class="mr-2"
            />Export
          </CButton>
        </CButtonGroup>
        <hr />
        <CDataTable
          :items="computedItems"
          :fields="fields"
          hover
          small
          border
          v-show="!formVisibility"
          :loading="loading"
        >
          <template #thead-top>
            <tr>
              <th
                rowspan="2"
                style="vertical-align: middle; width: 10px"
                class="text-center border-bottom"
              >
                NO
              </th>
              <th
                rowspan="2"
                style="vertical-align: middle"
                class="text-center border-bottom"
              >
                Nama Perangkat Daerah
              </th>
              <th colspan="2" class="text-center">Jenis Kelamin</th>
              <th
                rowspan="2"
                style="vertical-align: middle"
                class="text-center border-bottom"
              >
                Total
              </th>
            </tr>
          </template>
        </CDataTable>
        <CPagination
          :activePage.sync="page"
          :pages.sync="totalPage"
          size="sm"
          align="end"
          v-show="!formVisibility"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import VSelect from "vue-select";
import _ from "lodash";
import axios from "axios";
const fields = [
  {
    key: "number",
    label: "No",
    _style: "display:none;",
    _classes: "text-center",
  },
  { key: "nama_unit_kerja", label: "Nama OPD/SKPD", _style: "display:none;" },
  { key: "pria", label: "Laki-Laki", _classes: "text-center" },
  { key: "wanita", label: "Perempuan", _classes: "text-center" },
  {
    key: "total",
    label: "Total",
    _style: "display:none;",
    _classes: "text-center",
  },
];
export default {
  name: "PerJenisKelamin",
  components: {
    VSelect,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      formVisibility: false,
      loading: false,
      loading_export: false,
      page: 1,
      totalPage: 0,
      optionsOPD: [],
      form: {
        id_unit_kerja: null,
      },
    };
  },
  watch: {
    page: function () {
      this.loadData();
    },
  },
  computed: {
    computedItems() {
      var number = 1;
      let total_pages = 10 * (this.page - 1);
      return this.items.map((item) => {
        var total = parseInt(item.pria) + parseInt(item.wanita);
        return {
          ...item,
          number: number++ + total_pages,
          total: total,
        };
      });
    },
    title() {
      return "Jumlah PNS Berdasarkan Jenis Kelamin";
    },
  },
  mounted() {
    this.loadData();
    this.searchOPD("", this);
  },
  methods: {
    reset() {
      this.form.id_unit_kerja = null;
      this.page = 1;
      this.loadData();
    },
    async loadData() {
      try {
        this.loading = true;
        let data = await this.$store.dispatch(
          "laporan_per_jenis_kelamin/index",
          {
            page: this.page,
            data: this.form,
          }
        );
        this.items = data.data;
        this.totalPage = data.last_page;
      } catch (ex) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
    resetTabel() {
      this.loadData();
    },
    async onSearchOPD(search, loading) {
      loading(true);
      await this.searchOPD(search, this);
      loading(false);
    },
    searchOPD: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "laporan_per_opd_skpd/autocomplete_opd_skpd",
        search
      );
      if (search) vm.optionsOPD = options;
    }, 300),
    async exportExcel() {
      this.loading_export = true;
      await axios
        .get(
          "/api/laporan/per-jenis-kelamin/export?id_unit_kerja=" +
            this.form.id_unit_kerja,
          { responseType: "arraybuffer" }
        )
        .then((response) => {
          const current = new Date();
          const yyyy = current.getFullYear();
          let mm = current.getMonth() + 1;
          let dd = current.getDate();
          if (dd < 10) dd = "0" + dd;
          if (mm < 10) mm = "0" + mm;
          const date = dd + "" + mm + "" + yyyy;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "PerJenisKelamin_" + date + ".xlsx"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading_export = false;
        });
    },
  },
};
</script>